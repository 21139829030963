import { useMemo } from 'react';

import { paths } from 'src/routes/paths';

import { useTranslate } from 'src/locales';

import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
  // OR
  // <Iconify icon="fluent:mail-24-filled" />
  // https://icon-sets.iconify.design/solar/
  // https://www.streamlinehq.com/icons
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  users: <Iconify icon="solar:users-group-rounded-bold-duotone" width={24} />,
  teams: <Iconify icon="fluent:people-team-16-filled" width={24} />,
  company: <Iconify icon="icon-park-twotone:building-one" width={24} />,
  candidate: <Iconify icon="icon-park-twotone:worker" width={24} />,

};

// ----------------------------------------------------------------------

export function useNavData() {
  const { t } = useTranslate();

  const data = useMemo(
    () => [
      // OVERVIEW
      // ----------------------------------------------------------------------
      {
        subheader: t('dashboard'),
        items: [
          {
            title: t('overview'),
            path: paths.dashboard.root,
            icon: ICONS.dashboard,
          },

        ],
      },

      // User MANAGEMENT
      // ----------------------------------------------------------------------
      {
        subheader: t('User MANAGEMENT'),
        items: [

          {
            title: t('Profile'),
            path: paths.dashboard.profile.root,
            icon: ICONS.user,
          },

          // USER
          {
            title: t('backoffice users'),
            roles: ['super_admin'],
            path: paths.dashboard.users.root,
            icon: ICONS.users,

          },
          // companies
          {
            title: t('companies'),
            path: paths.dashboard.companies.root,
            icon: ICONS.banking,

          },
          // candidates
          {
            title: t('candidates'),
            path: paths.dashboard.candidates.root,
            icon: ICONS.teams,

          },

          // customers
          // {
          //   title: t('customers'),
          //   roles: ['Super Admin'],
          //   path: paths.dashboard.invoice.root,
          //   icon: ICONS.invoice,
          //   children: [
          //     { title: t('list'), path: paths.dashboard.invoice.root },
          //     {
          //       title: t('details'),
          //       path: paths.dashboard.invoice.demo.details,
          //     },
          //     { title: t('create'), path: paths.dashboard.invoice.new },
          //     { title: t('edit'), path: paths.dashboard.invoice.demo.edit },
          //   ],
          // },
        ]
      },
            // Contacts
      // ----------------------------------------------------------------------
      // {
      //   subheader: t('Contacts'),
      //   items: [
      //     // CHAT
      //     {
      //       title: t('chat'),
      //       path: paths.dashboard.chat,
      //       icon: ICONS.chat,
      //     },


      //   ]
      // },

            // tickets
      //----------------------------------------------------------------------
      {
        subheader: t('Ticket MANAGEMENT'),
        items: [
          // TICKET
          {
            title: t('tickets'),
            path: paths.dashboard.tickets.root,
            icon: ICONS.blank,

          },

          {
            title: t('chat'),
            path: paths.dashboard.chat,
            icon: ICONS.chat,
          },

        ]
      },





    ],
    [t]
  );

  return data;
}
