import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import DashboardLayout from 'src/layouts/dashboard';
import { AuthGuard,RoleBasedGuard } from 'src/auth/guard';

import { LoadingScreen } from 'src/components/loading-screen';

// ----------------------------------------------------------------------

// OVERVIEW
const IndexPage = lazy(() => import('src/pages/dashboard/app'));

// PROFILE
const ProfilePage = lazy(() => import('src/pages/dashboard/profile/profile'));
const ProfileEditPage = lazy(() => import('src/pages/dashboard/profile/edit'));

// USER
const UserListPage = lazy(() => import('src/pages/dashboard/user/list'));
const UserCreatePage = lazy(() => import('src/pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('src/pages/dashboard/user/edit'));

// COMPANY
const CompanyListPage = lazy(() => import('src/pages/dashboard/company/list'));
const CompanyViewPage = lazy(() => import('src/pages/dashboard/company/details'));

// CANDIDATE
const CandidateListPage = lazy(() => import('src/pages/dashboard/candidate/list'));
const CandidateViewPage = lazy(() => import('src/pages/dashboard/candidate/details'));

// TICKET
const TicketListPage = lazy(() => import('src/pages/dashboard/ticket/list'));
const TicketAssignPage = lazy(() => import('src/pages/dashboard/ticket/assign'));
const TicketDetailsPage = lazy(() => import('src/pages/dashboard/ticket/details'));
const TicketViewPage = lazy(() => import('src/pages/dashboard/ticket/view'));

// APP
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));


// TEST RENDER PAGE BY ROLE
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// BLANK PAGE
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

export const dashboardRoutes = [
  {
    path: 'dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout>
          <Suspense fallback={<LoadingScreen />}>
            <Outlet />
          </Suspense>
        </DashboardLayout>
      </AuthGuard>
    ),
    children: [
      { element: <IndexPage />, index: true },

      { path: 'profile',
        children:[
          { element: <ProfilePage />, index: true },
          { path: 'edit', element: <ProfileEditPage /> },
        ]
      },

      {
        path: 'users',
        children: [
          { element:
            <RoleBasedGuard hasContent roles={['super_admin']} sx={{ py: 10 }}>
              <UserListPage />
            </RoleBasedGuard>,
            index: true
          },
          { path: 'new', element:
            <RoleBasedGuard hasContent roles={['super_admin']} sx={{ py: 10 }}>
              <UserCreatePage />
            </RoleBasedGuard>,
          },
          { path: 'edit/:id', element:
            <RoleBasedGuard hasContent roles={['super_admin']} sx={{ py: 10 }}>
              <UserEditPage />
            </RoleBasedGuard>,
          },
        ],
      },

      {
        path: 'companies',
      children:[
        { element: <CompanyListPage />, index: true },
        { path: 'details/:id', element: <CompanyViewPage /> },
      ]
      },

      {
        path: 'candidates',
      children:[
        { element: <CandidateListPage />, index: true },
        { path: 'details/:id', element: <CandidateViewPage /> },
      ]
      },

      {
        path: 'tickets',
        children: [
          {
            element: <TicketListPage />,
            index: true
          },
          {
            path: 'assign/:id',
            element: <TicketAssignPage />,
          },
          {
            path: 'details/:id',
            element: <TicketDetailsPage />,
          },
          {
            path: 'view/:id',
            element: <TicketViewPage />,
          },
        ],
      },

      { path: 'chat', element: <ChatPage /> },

      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];
