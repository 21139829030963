import axios from 'axios';

import { HOST_API } from 'src/config-global';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({ baseURL: HOST_API });

axiosInstance.interceptors.response.use(
  (res) => res,
  (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
);

export default axiosInstance;

// ----------------------------------------------------------------------

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosInstance.get(url, { ...config });

  return res.data;
};

// ----------------------------------------------------------------------

export const endpoints = {

  profile:{
    update:'/api/back/profile-update',
    avater:'/api/back/profile-avatar-update',
    resetRequest: '/api/back/user-password-reset-request',
    reset: '/api/back/user-password-reset',
    delete: '/api/back/delete-me',
  },

  users: {
    list: '/api/back/users',
    store: '/api/back/users-store',
    avater:'/api/back/users-avatar-update',
    getUser:'/api/back/get-user',
    delete: '/api/back/users-delete',
    details: '/api/back/users/details',
    search: '/api/back/users/search',
  },

  candidates: {
    list: '/api/back/candidates',
    getCandidate: '/api/back/get-candidate',
  },

  companies: {
    list: '/api/back/companies',
    getCompany: '/api/back/get-company',
  },

  ticket: {
    list: '/api/back/tickets',
    get: '/api/back/get-ticket',
    userAssign: '/api/back/tickets/user-assign',
    ticketPriority: '/api/back/tickets/ticket-priority',
    send: '/api/back/tickets/ticket-send',
    file: '/api/back/tickets/file-upload',
    close: '/api/back/tickets/close',
  },


  chat: '/api/back/chat',
  kanban: '/api/kanban',
  calendar: '/api/calendar',

  auth: {
    me: '/api/back/me',
    login: '/api/back/login',
    register: '/api/auth/register',
  },

  mail: {
    list: '/api/mail/list',
    details: '/api/mail/details',
    labels: '/api/mail/labels',
  },

  post: {
    list: '/api/post/list',
    details: '/api/post/details',
    latest: '/api/post/latest',
    search: '/api/post/search',
  },

  product: {
    list: '/api/product/list',
    details: '/api/product/details',
    search: '/api/product/search',
  },

};


// export const updateReduxLoadScreen = (x) => {
//   store.dispatch({ type: 'USER', payload: x });
// }
